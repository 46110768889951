.showcase-container{
    display: flex; 
    flex-wrap: wrap; 
    background: black;
    box-shadow: 0px 1px 3px 2px rgb(0, 68, 255),
                2px 3px 1px 0px purple;
    border: 1px solid rgb(226, 2, 96);
    border-radius: 15px;  
    width: 100%;    
}

/*-----------------Header-------------------------------------------*/

.showcase-container h1{
    width: 100%;  
    text-align: center; 
    text-decoration: underline; 
    font-size: 40px; 
    text-shadow: 1px 1px 2px black, 0 0 25px rgb(226, 2, 96), 0 0 5px rgb(139, 0, 86);
}

/*-------------------Header Horizontal Rule-------------------------------------------*/

.showcase-hr{
    margin: 0px; 
    width: 100%; 
}

.hr {
    width: 82%; 
    border-color: rgb(226, 2, 96);
    box-shadow: 0px 1px 3px 2px rgb(0, 68, 255),
    2px 3px 1px 0px purple;
}

/*--------------------Row 1 Content-------------------------------------------------------*/

.row-1{
    width: 100%; 
    padding: 20px; 
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: column; 
    align-items: center; 
}

.section-2{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
}

.section-2 h2{
    text-decoration: underline; 
    text-shadow: 1px 1px 2px black, 0 0 25px rgb(226, 2, 96), 0 0 5px rgb(139, 0, 86);
    padding: 15px 0px 0px 0px; 
}

.section-2 p{
    letter-spacing: 1px; 
    line-height: 1.5; 
    font-weight: 600; 
    font-size: 18px; 
    margin-top: 0px; 
    padding-left: 10px; 
    letter-spacing: 1.5px; 
}

@media(min-width: 300px){
    .section-2 p{
        padding-left: 0px;
        font-size: 13px;
        width: 96%;
    }
}

.section-2 a{
    color: rgb(0, 0, 238);
}


/*--------------------------------------Row-1 Image-----------------------------*/

.showcase-image{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    max-width: 500px; 
    width: 100%; 
    overflow:hidden;
}

@media(max-width: 957px){
    .showcase-image{
        margin-top: 20px;  
    }
}

@media(max-width: 857px){
    .showcase-image{
        margin-top: 20px; 
    }
}

@media(min-width: 300px){
    .showcase-image{
        width: 96%;  
        margin-top: 10px; 
    }
}

.showcase-image-2{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    max-width: 300px; 
    width: 100%; 
    height: 200px; 
    padding-right: 5%; 
    overflow:hidden;
}

@media(min-width: 300px){
    .showcase-image-2{
        width: 96%;  
        padding-right: 0%; 
        max-width: 375px; 
    }
}

@media(min-width: 550px){
    .showcase-image-2{
        width: 45%;
        padding-right: 5%;
    }
}

@media(min-width: 738px){
    .showcase-image-2{
        width: 100%;
        max-width: 420px; 
        padding: 0%; 
    }
}

@media(min-width: 825px){
    .showcase-image-2{    
        padding-right: 5%; 
        width: 45%;
        max-width: 350px; 
    }
}

.pickem-img{
    background-position: bottom;
    background-size: cover; 
    background-repeat: no-repeat; 
    max-height: 250px; 

}

.pickem-img-2{
    background-position: bottom;
    background-size: cover; 
    background-repeat: no-repeat; 
}

/*----------------------------------------Row 2----------------------------------*/

.row-2{
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: row-reverse;
    justify-content: space-around;
    width: 100%; 
    padding: 20px; 
}

@media(min-width: 320px){
    .row-2{
        align-items: center; 
        width: 100%;
    }
}

@media(min-width: 550px){
    .row-2{
        align-items: center; 
        width: 89%;
    }
}
@media(min-width: 738px){
    .row-2{
        width: 100%;
    }
}

@media(min-width: 825px){
    .row-2{
        width: 89%
    }
}

.list{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    width: 50%; 
}

@media(min-width: 300px){
    .list{
        width: 100%; 
        margin-top: 20px; 
        justify-content: space-evenly;
    }
}

@media(min-width: 550px){
    .list{
       width: 50%; 
    }
}

@media(min-width: 680px){
    .list{
        width: 50%; 
    }
}

.list p{
    margin-right: 10px; 
}


.row-2 h2{
    text-decoration: underline;
    text-align: center; 
    width: 100%; 
    margin-top: 0px; 
    margin-bottom: 0px; 
    text-shadow: 1px 1px 2px black, 0 0 25px rgb(226, 2, 96), 0 0 5px rgb(139, 0, 86);
}


/*----------------------------------Row-2 list--------------------------------------------------------------------------*/

.row-2 ul{
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5; 
}



@media(max-width: 1150px){
    .row-2 ul{
        font-size: 15px; 
    }
}

/*---------------------------------Contributions--------------------------------------------------------------------------*/
#membership{
    font-size: 14px; 
    text-shadow: 1px 1px 2px black, 0 0 25px rgb(226, 2, 96), 0 0 5px rgb(139, 0, 86);
}


.contributions-container{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    width: 100%; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
}

.contributions-container h2{
    text-shadow: 1px 1px 2px black, 0 0 25px rgb(226, 2, 96), 0 0 5px rgb(139, 0, 86);
    text-decoration: underline;    
}

.contributions-container p{
    font-weight: 800;
    font-size: 15px; 
}

.section-2 span{
    width: 100%;
}