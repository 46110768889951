
.skills-container{
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: column; 
    background: black;
    justify-content: space-around;
    box-shadow: 0px 1px 3px 2px rgb(226, 2, 96); 
    border-radius: 15px;  
    padding: 20px; 
    width: 100%;
    align-items: center; 
}

/*---------------------------------------IMAGE-----------------------------------------------*/

.skills-image{
    width: 75%; 
    max-height: 100px; 
    height: 100%; 
}

/*-------------------------------------ABOUT CONTENT------------------------------------------*/

.skills-content h1{
    font-size: 50px; 
    margin: 0px 0px 20px 0px;  
    text-decoration: underline; 
    color: white; 
    letter-spacing: 2px; 
    text-shadow: 1px 1px 2px black, 0 0 25px rgb(226, 2, 96), 0 0 5px rgb(139, 0, 86);
}

.skills-content{
    line-height: 1.5; 
}

.skills-main-content{
    display: flex; 
    flex-wrap: wrap; 
    line-height: 1.5;
    font-size: 14px;  
    justify-content: space-evenly;  
    width: 65%; 
    margin-bottom: 20px; 
}

@media(max-width: 395px){
    .skills-main-content{
        width: 100%; 
    }
}

/*-----------------------------------HORIZONTAL RULE-------------------------------------------*/

.horizontal-rule-skills{
    width: 100%; 
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center;
    margin-bottom: 20px; 
}

.horizontal-rule-skills hr{
    height: 0px; 
    border-color: rgb(226, 2, 96);
    width: 90%;
    margin: 0px;
    box-shadow: 0px 1px 3px 2px rgb(255, 0, 119),
                2px 3px 1px 0px rgb(70, 0, 35); 
}

/*----------------------------------BUBBLES----------------------------------------*/

.skill-bubble{
    font-weight: 700; 
    text-shadow: 1px 1px 2px black, 0 0 25px rgb(226, 2, 96), 0 0 5px rgb(139, 0, 86);
    padding: 5px;
    display: flex; 
    flex-wrap: wrap; 
    align-items: center; 
    justify-content: center; 
    background-size: cover; 
    background-position: center; 
    border-radius: 5px; 
    min-width: 80px; 
    margin: 10px;
    border: 1px solid rgb(255, 0, 119); 
}


@media(max-width: 400px){
    .skill-bubble{
        width: 30%;
    }  
}

@media(min-width: 401px) and (max-width: 490px){
    .skill-bubble{
        width: 100%; 
        margin: 15px;   
    }  

    .skill-bubble:hover{
        background: purple; 
    }
 }
