
/* background-color: #282c34; */

html {
  height: 100%;
  margin: 0 auto;  
  width: 100%; 
  background-image: url('components/Images/image1.jpg');
  background-size: cover; 
  background-position: top center;  
}

body{
  font-family: 'Poiret One', cursive;
  color: white; 
  padding-bottom: 30px; 
}

.main-container{
  display: flex; 
  flex-wrap: wrap; 
}

  @media(max-width: 850px){
    .main-container{
        width: 100%; 
        justify-content: center; 
    }
  }

.main-content{
  display: flex; 
  flex-wrap: wrap;  
  margin-top: 30px;
  width: 70%;  
}



@media(max-width: 737px){
  .main-content{
    width: 95%;
  }
}


  






