.menu{
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: column; 
    font-size: 22px; 
    transition: 2s; 
    letter-spacing: 2px;  
    align-items: center; 
    width: 27%;
    border-radius: 15px; 
}

@media(max-width: 737px){
    .menu{
        width: 100%; 
        background: rgba(0,0,0,0.9); 

    }
}

.menu h2{
    text-decoration: underline; 
}

.menu a {
    padding: 10px;
    color: white;
    text-decoration: none; 
}

.menu a:hover{
  font-size: 40px; 
  font-weight: bold;  
  text-shadow: 1px 1px 2px black, 0 0 25px rgb(226, 2, 96), 0 0 5px rgb(139, 0, 86);

}

.hidden{
    opacity: 0; 
    transition: 2s;   
}

@media(max-width: 937px){
    .hidden{
        display: none; 
    }
}