
nav{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-between; 
    padding: 30px 50px 0px 50px; 
}

@media(max-width: 461px){
    nav{
        padding: 10px 10px;  
    }
}

nav div{
    display: flex; 
    flex-wrap: wrap; 
    align-items: center; 
}

nav h2 {
    font-size: 27px; 
    letter-spacing: 2px; 
    padding-left: 35px; 
    text-shadow: 1px 1px 2px black, 0 0 25px rgb(226, 2, 96), 0 0 5px rgb(139, 0, 86);
}

@media(max-width: 461px){
    nav h2{
        padding: 0px; 
        font-size: 22px;
    }
}


.mobile-envelope{
    display: none; 
}

@media(max-width: 617px){
    .mobile-envelope{
        display: flex; 
        padding: 0px;  
    }
    .mobile-nav-header{
      width: 100%;
      justify-content: space-between;}
  }




/*--------------------ICONS-------------------------------------------------------------*/

i{
    font-size: 30px;  
    cursor: pointer; 
}

.fab{
    padding-left: 25px; 
    color: white;
}

.fab:hover{
  color: darkblue; 
}

.far:hover{
  color: darkblue
}

@media(max-width: 621px){
    .fab{
        display: none;  
        padding-left: 0px; 
    }
}

.far{
    padding-left: 25px; 
    font-size: 33px; 
}

@media(max-width: 461px){
    .far{
        padding-left: 0px;
    }  
}

@media(max-width: 621px){
    .desktop-envelope{
        display: none; 
    }
}

/*-----------------------------------Hamburger------------------------------------------------*/

.hamburger-container{
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: column;    
}

.bar{
    height: 1px;
    width: 38px;
    margin: 5px 0px; 
    box-shadow:
    -1px -1px 0 maroon,
    1px -1px 0 violet,
    -1px 1px 0 purple,
    1px 1px 0 darkslateblue;  
}

.bar1{
    animation-name: colorwheel2; 
    animation-duration: 1.5s; 
    animation-timing-function: ease; 
    animation-iteration-count: infinite;
}
  
.bar2{
    animation-name: colorwheel; 
    animation-duration: 1s; 
    animation-timing-function: ease; 
    animation-iteration-count: infinite;
}
  
.bar3{ 
    animation-name: colorwheel1; 
    animation-duration: 1s; 
    animation-timing-function: ease; 
    animation-iteration-count: infinite;
}
  

@keyframes colorwheel1{
    0%{
        background-color: violet; 
    }10%{
        background-color: pink; 
    }20%{
        background-color: violet; 
    }30%{
        background-color: rgb(255, 174, 0); 
    }40%{
        background-color: green; 
    }50%{
        background-color: blue; 
    }60%{
        background-color: pink; 
    }70%{
        background-color: red; 
    }90%{
        background-color: turquoise; 
    }100%{
        background-color: pink; 
    }
}


@keyframes colorwheel{
    0%{
        background-color: pink; 
    }10%{
        background-color: violet; 
    }20%{
        background-color: turqoise; 
    }30%{
        background-color: yellow; 
    }40%{
        background-color: green; 
    }50%{
        background-color: pink; 
    } 60%{
        background-color: blue; 
    }70%{
        background-color: red; 
    }90%{
        background-color: violet; 
    }100%{
        background-color: turqoise; 
    }
}



@keyframes colorwheel2{
    0%{
        background-color: green; 
    }10%{
        background-color: red; 
    }20%{
        background-color: violet; 
    }30%{
        background-color: pink; 
    }40%{
        background-color: maroon; 
    }50%{
        background-color: pink; 
    }60%{
        background-color: violet; 
    }70%{
        background-color: turquoise; 
    }90%{
        background-color: blue; 
    }100%{
        background-color: yellow; 
    }
}
