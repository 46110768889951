.contact-container{
    display: flex; 
    flex-wrap: wrap; 
    background: black;
    align-items: center; 
    justify-content: space-around; 
    box-shadow: 0px 1px 3px 2px rgb(226, 2, 96); 
    border-radius: 15px; 
    width: 90%; 
    margin-top: 50px; 
    padding: 10px 20px; 
}

@media(max-width: 1270px){
    .contact-container{
        width: 100%; 
    }
}

@media(max-width: 1120px){
    .contact-container{
        margin-top: 0px; 
        flex-direction: column; 
        padding: 10px; 
    }
}

.mobile-header{
    display: none; 
}

@media(max-width: 1120px){
    .mobile-header{
        display: flex;
        font-size: 40px; 
    }
}

/*-----------------------------------IMAGE--------------------------------------------*/

.image{
    max-width: 300px;
}

img{
    width: 100%; 
    box-shadow: 0px 1px 3px 2px white; 
    border-radius: 5px; 
}

@media(max-width: 1180px){
    .image{
        width: 100%; 
        max-width: 260px;
    }
}

@media(max-width: 1215px){
    .image{
       max-width: 260px;   
    }
}

/*----------------------------------CONTACT INFO---------------------------------------*/

.contact-container h1{
    text-decoration: underline; 
    line-height: .7; 
    margin-bottom: 30px; 
    text-shadow: 1px 1px 2px black, 0 0 25px rgb(226, 2, 96), 0 0 5px rgb(139, 0, 86);
 
}

.contact{
    font-size: 18px; 
    letter-spacing: 1.0px; 
    font-weight: 600; 
}

@media(max-width: 1120px){
    .contact{
        text-align: center;  
    }
    .contact h1{
       display: none;    
    }
}

.contact a{
    text-decoration: none; 
}

/*-------------------------------------------------------------------------------------*/

.contact-container hr{
     
    height: 95%; 
    border-color: rgb(226, 2, 96);
    box-shadow: 0px 1px 3px 2px rgb(0, 68, 255),
    2px 3px 1px 0px purple;
    margin: 0px 5px; 
}

@media(max-width:1120px){
    .contact-container hr{
     height: 0px; 
     width: 75%;  
     margin: 25px auto 15px auto; 
    }
}