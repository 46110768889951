.project-container{
    display: flex; 
    flex-wrap: wrap; 
    background: black;
    align-items: center; 
    justify-content: space-around; 
    box-shadow: 0px 1px 3px 2px rgb(226, 2, 96); 
    border-radius: 15px;  
    width: 100%;     
}

@media(max-width: 550px){
    .project-container{
       flex-direction: column;   
    }
}

.project{
    width: 45%;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
    padding-bottom: 20px;  
}

@media(max-width: 550px){
    .project{
       width: 90%;  
    }
}

.project p{
    font-size: 14px; 
    letter-spacing: 1.2px; 
}

.project-container .project h1{
    font-size: 30px; 
    margin-bottom: 10px; 
}

.project-image{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    max-width: 250px; 
    width: 100%; 
    height: 200px; 
}

.project-img{
    background-position: bottom;
    background-size: cover; 
    background-repeat: no-repeat; 
    border-color:rgb(0, 68, 255);
    box-shadow: 0px 1px 3px 2px  rgb(226, 2, 96),
    2px 3px 1px 0px purple;
}

.project-img2{
    background: url('../../Images/magenta_room.jpg');
    background-position: bottom;
    background-size: cover; 
    background-repeat: no-repeat; 
    border-color:rgb(0, 68, 255);
    box-shadow: 0px 1px 3px 2px  rgb(226, 2, 96),
    2px 3px 1px 0px purple;
}

.project-horizontal-rule{
    width: 90%; 
    border-color: rgb(226, 2, 96);
    box-shadow: 0px 1px 3px 2px rgb(0, 68, 255),
    2px 3px 1px 0px purple;
}

.project-container h1{
    width: 100%; 
    font-size: 45px; 
    text-align: center; 
    text-shadow: 1px 1px 2px black, 0 0 25px rgb(226, 2, 96), 0 0 5px rgb(139, 0, 86);
    text-decoration: underline;
} 

.project-container p{
    font-size: 15px; 
    font-weight: 600;   
}

.project a{
    color: white; 
    font-weight: 600; 
    color: purple;
}


.project a:hover{
    color: rgb(226, 2, 96)
}
