
.about-container{
    display: flex; 
    flex-wrap: wrap; 
    background: black;
    align-items: center; 
    justify-content: center;
    box-shadow: 0px 1px 3px 2px rgb(226, 2, 96); 
    border-radius: 15px;  
    padding: 20px; 
    width: 100%;    
}

@media(max-width: 850px){
    .about-container{
        padding: 20px 10px;  
    }  
 }

@media(max-width: 490px){
    .about-container{
        padding: 0px 20px;  
    }  
 }

/*---------------------------------------IMAGE-----------------------------------------------*/

.about-me-image{
    background: url('../../Images/IMG_1282.jpeg');
    background-position: center; 
    background-size: cover; 
    background-repeat: no-repeat; 
    max-height: 175px;
    border-radius: 10%; 
    width: 40%; 
    height: 100%;
    box-shadow: 0px 1px 3px 2px rgb(226, 2, 96);   
}

@media(max-width: 490px){
    .about-me-image{
        width: 95%; 
        height: 150px;
        border-radius: 5px;  
    }  
 }

/*-------------------------------------ABOUT CONTENT------------------------------------------*/

.about-content h1{
   font-size: 40px; 
   text-decoration: underline; 
   text-shadow: 1px 1px 2px black, 0 0 25px rgb(226, 2, 96), 0 0 5px rgb(139, 0, 86);

}

.about-content{ 
    width: 50%; 
    font-size: 19px; 
    letter-spacing: 1.2px; 
    font-weight: 600; 
}

@media(max-width: 850px){
    .about-content{ 
        letter-spacing: 1px; 
    }  
 }

@media(max-width: 490px){
    .about-content{
        width: 100%; 
        text-align: center;  
    }  
 }

.about-content p {
    line-height: 1.4;
    font-size: 19px; 
}

@media(max-width: 850px){
    .about-content p{
        font-size: 16px;
    }  
 }

 @media(max-width: 738px){
    .about-content p{
        font-size: 18px;
    }  
 }

 @media(max-width: 550px){
    .about-content p{
        font-size: 16px;
    }  
 }
.about-main-content{
    display: flex; 
    flex-wrap: wrap; 
    line-height: 1.5;
    font-size: 16px;  
    font-weight: 600; 
    letter-spacing: 1px; 
    justify-content: center;  
    width: 90%; 
}

@media(max-width: 490px){
    .about-main-content{
        width: 100%; 
        
    }  
 }

 @media(max-width: 490px){
    .content-mobile{
        display: none;  
    }  
 }
/*-----------------------------------HORIZONTAL RULE-------------------------------------------*/

.horizontal-rule{
    width: 100%; 
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center;
}

.horizontal-rule hr{
    margin: 30px 0px 15px 0px; 
    border-color: rgb(226, 2, 96);
    width: 90%;
    box-shadow: 0px 1px 3px 2px rgb(90, 0, 30),
    2px 3px 1px 0px rgb(85, 0, 25);
}

@media(max-width: 490px){
    .horizontal-rule hr{
       width: 96%;  
    }
}
